import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { contactByIdSelector, contactsSelector } from 'core/useCases/contacts/selectors';
import { SSpacedColumn } from 'styles/layout';
import { POST, PUT } from 'utils/http';
import TextInput from '../lib/TextInput';
import { DateOrTimeInput, SelectInput } from '../lib';
import { OptionsProps } from '../lib/SelectInput';
import { enumKeys } from 'utils/common';
import { concatDateTime } from 'utils/dateTimeFormat';
import Event from 'core/models/event';
import EventType from 'core/enums/eventType';
import EventMediaType from 'core/enums/eventMediaType';
import InterviewType, { InterviewTypeWithApplication, InterviewTypeWithoutApplication } from 'core/enums/interviewType';
import { AddEvent, UpdateEvent } from 'core/useCases/events/eventActions';
import { RootState } from 'core/store';
import { getEventMediaOptionsForContact } from 'core/useCases/contacts/utils';
import { getApplicationLabel } from 'core/useCases/applications/utils';
import { applicationByIdSelector, applicationsSelector } from 'core/useCases/applications/selectors';
import ResourcesInput from '../lib/ResourcesInput';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { logEvent } from 'config/tracking';
import { TrackingEventName } from 'utils/types/amplitude';
import useResponsive from 'hooks/responsive';
import { OpenModal } from 'core/useCases/modal/modalAction';
import ActivityType from 'core/enums/activityType';
import EventStatus from 'core/enums/eventStatus';

type Inputs = {
    applicationId: string;
    type: EventType;
    subject?: InterviewType;
    contactId?: string;
    mediaType?: EventMediaType;
    mediaDetails?: string;
    date: Date;
    time: Date;
    comments?: string;
};

type CreateOrUpdateEventResponse = {
    event: Event;
};

const createEventRequest = async (inputs: Inputs, eventType: EventType): Promise<Event> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { time, ...data } = inputs;

    const response = (await POST<CreateOrUpdateEventResponse>(`${BACKEND_URL}/api/event`, {
        ...data,
        type: eventType,
    })) as CreateOrUpdateEventResponse;

    return response.event;
};

const updateEventRequest = async (inputs: Inputs, id: string): Promise<Event> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { time, ...data } = inputs;
    const response = (await PUT<CreateOrUpdateEventResponse>(`${BACKEND_URL}/api/event/${id}`, {
        ...data,
    })) as CreateOrUpdateEventResponse;

    return response.event;
};

const computeData = (event?: Partial<Event>): Partial<Inputs> | undefined => {
    if (!event) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { contact, application, id, date, ...data } = event;
    const computedData = {
        ...data,
        applicationId: application?.id,
        contactId: contact?.id,
        date: date ? new Date(date) : undefined,
        time: date ? new Date(date) : undefined,
    } as Partial<Inputs>;

    return computedData;
};

type EventFormProps = {
    closeModal(): void;
    defaultValues: Partial<Event>;
    disabledFields?: Array<DisabledField>;
};

const EventForm: FC<EventFormProps> = ({ closeModal, defaultValues, disabledFields }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const initialEventType = defaultValues?.type || EventType.Interview || EventType.Event;

    const contactList = useSelector(contactsSelector);
    const [contactOptions, setContactOptions] = useState<OptionsProps>([]);
    const [subjectOptions, setSubjectOptions] = useState<OptionsProps>([]);
    const [mediaTypeOptions, setMediaTypeOptions] = useState<OptionsProps>([]);
    const applicationOptions = useSelector(applicationsSelector).map((application) => ({
        label: getApplicationLabel(application, t),
        value: application.id,
    }));

    const methods = useForm<Inputs>({
        defaultValues: computeData(defaultValues),
    });
    const { handleSubmit, control, watch, setValue } = methods;
    const selectedContactId = watch('contactId');
    const selectedMediaType = watch('mediaType');
    const selectedSubject = watch('subject');
    const selectedApplicationId = watch('applicationId');
    const selectedContact = useSelector((state: RootState) => contactByIdSelector(state, selectedContactId));
    const selectedApplication = useSelector((state: RootState) =>
        applicationByIdSelector(state, selectedApplicationId),
    );

    useEffect(() => {
        if (selectedSubject === 'exhibition' || selectedSubject === 'webinar') {
            setValue('type', EventType.Event);
        } else {
            setValue('type', EventType.Interview);
        }
    }, [selectedSubject, setValue]);

    const onSubmit = handleSubmit(async (values: Inputs) => {
        let newEvent: Event = {} as Event;
        const eventType = values.type;
        try {
            if (defaultValues?.id) {
                // Remove time from values
                const { date, time } = values;
                const updatedEvent = await updateEventRequest(
                    { ...values, date: concatDateTime(date, time) },
                    defaultValues.id,
                );
                dispatch(UpdateEvent(updatedEvent));
            } else {
                // Remove time from values
                const { date, time } = values;
                newEvent = await createEventRequest({ ...values, date: concatDateTime(date, time) }, eventType);
                dispatch(AddEvent(newEvent));
                logEvent({
                    name: TrackingEventName.EVENT_CREATED,
                    params: { eventId: newEvent.id, subject: newEvent.subject },
                });
            }
            toast.success(t(`event-form.${defaultValues?.id ? 'update' : 'creation'}.success`));
            closeModal();

            if (newEvent && newEvent.createdDate && newEvent.createdDate > newEvent.date) {
                const { status } = newEvent;
                dispatch(
                    OpenModal({
                        defaultValues: {
                            activityType: ActivityType.Event,
                            id: newEvent.id,
                            isAlreadyCompleted: EventStatus.Completed === status,
                        },
                        title: t('common.mark-as-complete'),
                        type: ModalType.ActivityReport,
                    }),
                );
            }
        } catch {
            toast.error(t(`event-form.${defaultValues?.id ? 'update' : 'creation'}.error`));
        }
    });

    useEffect(() => {
        if (EventType.Interview === initialEventType || EventType.Event === initialEventType) {
            const subjectOptionsComputed: OptionsProps = [];
            for (const value of enumKeys(InterviewType)) {
                subjectOptionsComputed.push({
                    label: t(`common.interview-subject.enum.${InterviewType[value]}`),
                    value: InterviewType[value],
                });
            }
            setSubjectOptions(subjectOptionsComputed);
        }
    }, [setSubjectOptions, initialEventType]);

    useEffect(() => {
        if (selectedContact) {
            setMediaTypeOptions(getEventMediaOptionsForContact(selectedContact, t));
        } else {
            // If unselect contact, reset mediatype selected and reset the list of choices
            setMediaTypeOptions([]);
            setValue('mediaType', undefined);
        }
        if (selectedSubject && selectedSubject === 'exhibition') {
            setMediaTypeOptions([
                { label: t(`common.media-type.enum.face-to-face`), value: EventMediaType.FaceToFace },
                { label: t(`common.media-type.enum.video-conferencing`), value: EventMediaType.VideoConferencing },
            ]);
        } else if (selectedSubject && selectedSubject === 'webinar') {
            setMediaTypeOptions([
                { label: t(`common.media-type.enum.video-conferencing`), value: EventMediaType.VideoConferencing },
            ]);
        }
    }, [setMediaTypeOptions, selectedContact, selectedContactId, selectedSubject, setValue]);

    useEffect(() => {
        // Reset mediaDetails if mediaType not concern with mediaDetails
        if (
            selectedMediaType &&
            ![EventMediaType.FaceToFace, EventMediaType.VideoConferencing].includes(selectedMediaType)
        ) {
            setValue('mediaDetails', undefined);
        }
    }, [selectedMediaType, setValue]);

    // Refresh subject options and contact options according to application selected or not
    useEffect(() => {
        const listOfSubject = selectedApplication ? InterviewTypeWithApplication : InterviewTypeWithoutApplication;

        setSubjectOptions(
            listOfSubject.map((subject) => ({
                label: t(`common.interview-subject.enum.${subject}`),
                value: subject,
            })),
        );

        if (selectedSubject && !listOfSubject.includes(selectedSubject)) {
            setValue('subject', undefined);
        }

        const contactsComputed = contactList.filter(
            (contact) => !selectedApplication || selectedApplication.contacts.map((c) => c.id).includes(contact.id),
        );

        const contactIdsComputed = contactsComputed.map((contact) => contact.id);

        setContactOptions(
            contactsComputed.map((contact) => ({
                label: `${contact.firstName} ${contact.lastName}`,
                value: contact.id,
            })),
        );

        // If selected application have changed with a value, reset contact
        // because the contact should be in the list of application linked contact
        if (selectedApplication && selectedContactId && !contactIdsComputed.includes(selectedContactId)) {
            setValue('contactId', undefined);
        }
    }, [setSubjectOptions, selectedApplication, setValue, selectedSubject, selectedContactId, contactList]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SelectInput
                    control={control}
                    isDisabled={disabledFields && disabledFields.includes(DisabledField.ApplicationId)}
                    label={t('application.label')}
                    name="applicationId"
                    options={applicationOptions}
                    semi={!isMobile}
                />
                <SSpacedColumn>
                    <SelectInput
                        control={control}
                        isDisabled={disabledFields && disabledFields.includes(DisabledField.Subject)}
                        label={t('common.subject.label')}
                        name="subject"
                        options={subjectOptions}
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                    />
                    <SelectInput
                        control={control}
                        isDisabled={disabledFields && disabledFields.includes(DisabledField.ContactId)}
                        label={t('common.contact')}
                        name="contactId"
                        options={contactOptions}
                        placeholder={t('common.select-field-placeholder')}
                        required={!(selectedSubject && ['exhibition', 'webinar'].includes(selectedSubject))}
                        semi={!isMobile}
                    />
                </SSpacedColumn>
                <SSpacedColumn>
                    <SelectInput
                        control={control}
                        label={t('common.media-type.label')}
                        name="mediaType"
                        options={mediaTypeOptions}
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                    />
                    {/* eslint-disable-next-line max-len */}
                    {(!selectedMediaType ||
                        [EventMediaType.FaceToFace, EventMediaType.VideoConferencing].includes(selectedMediaType)) && (
                        <TextInput
                            label={t('common.media-details.label')}
                            name="mediaDetails"
                            placeholder={t('common.media-details.placeholder')}
                            semi={!isMobile}
                            required
                        />
                    )}
                </SSpacedColumn>
                <SSpacedColumn>
                    <DateOrTimeInput
                        control={control}
                        label={t('common.date')}
                        name="date"
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                    />
                    <DateOrTimeInput
                        control={control}
                        label={t('common.time')}
                        minDate={new Date()}
                        name="time"
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                        typeTime
                    />
                </SSpacedColumn>
                <TextInput
                    label={t('common.comment')}
                    name="comments"
                    placeholder={t('common.write-a-comment')}
                    isTextArea
                />
                <ResourcesInput name="resources" />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{defaultValues?.id ? t('common.update') : t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default EventForm;
