import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ResourceType from 'core/models/resource';
import AddResource from './AddResource';
import Resource from './Resource';
import { SWrapper, SLabel, SResources } from './styles';

type ResourcesInputProps = {
    name: string;
};

const ResourcesInput: FC<ResourcesInputProps> = ({ name }) => {
    const { t } = useTranslation();
    const { getValues, setValue } = useFormContext();
    const persistedValue = getValues(name);
    const [resources, setResources] = useState<Array<ResourceType>>(persistedValue ? JSON.parse(persistedValue) : []);

    const addResource = (resource: ResourceType) => {
        if (resource.url && resource.label) {
            setResources([...resources, resource]);
        }
    };

    const removeResource = (resource: ResourceType) => {
        setResources(resources.filter((r) => r.label !== resource.label || r.url !== resource.url));
    };

    useEffect(() => {
        setValue(name, JSON.stringify(resources));
    }, [resources]);

    return (
        <div>
            <SLabel>{t('common.resources.label')}</SLabel>
            <SWrapper>
                <AddResource onResourceAdded={addResource} />
                {Boolean(resources) && (
                    <SResources>
                        {resources.map(({ url, label }) => (
                            <Resource
                                key={`${url}${label}`}
                                label={label}
                                onRemoveResourceClicked={removeResource}
                                url={url}
                            />
                        ))}
                    </SResources>
                )}
            </SWrapper>
        </div>
    );
};

export default ResourcesInput;
