import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as Unchecked } from 'images/icons/Unchecked.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import Application from 'core/models/application';

type MarkApplicationAsCompleteButtonProps = {
    application: Application;
};

const MarkApplicationAsCompleteButton: FC<MarkApplicationAsCompleteButtonProps> = ({ application }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openMarkApplicationAsCompleteModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    id: application.id,
                    status: application.status,
                },
                title: t('application.mark-as-complete'),
                type: ModalType.ApplicationReport,
            }),
        );
    };

    return (
        <div>
            <button
                onClick={() => openMarkApplicationAsCompleteModal()}
                title={t('task-list.mark-task-as-complete')}
                type="button"
            >
                <Unchecked />
            </button>
        </div>
    );
};

export default MarkApplicationAsCompleteButton;
