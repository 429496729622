import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import BACKEND_URL from 'constants/backendUrl';
import { SSpacedColumn } from 'styles/layout';
import { POST, PUT } from 'utils/http';
import {
    TaskSubject,
    TaskSubjectWithApplication,
    TaskSubjectWithoutApplication,
    TaskSubjectWithoutContact,
} from 'core/enums/taskSubject';
import { contactByIdSelector, contactsSelector } from 'core/useCases/contacts/selectors';
import { TaskMediaType } from 'core/enums/taskMediaType';
import { enumKeys } from 'utils/common';
import { TaskPriority } from 'core/enums/taskPriority';
import { DateOrTimeInput, SelectInput, TextInput } from '../lib';
import { OptionsProps } from '../lib/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import Task from 'core/models/task';
import { AddTask, UpdateTask } from 'core/useCases/tasks/taskActions';
import { getTaskMediaOptionsForContact } from 'core/useCases/contacts/utils';
import { RootState } from 'core/store';
import { CloseModal } from 'core/useCases/modal/modalAction';
import { computeDateWithoutTime } from 'utils/dateTimeFormat';
import { applicationByIdSelector, applicationsSelector } from 'core/useCases/applications/selectors';
import { getApplicationLabel } from 'core/useCases/applications/utils';
import ResourcesInput from '../lib/ResourcesInput';
import { DisabledField } from 'core/useCases/modal/types';
import { logEvent } from 'config/tracking';
import { TrackingEventName } from 'utils/types/amplitude';
import { contactsFetcher } from 'contexts/ContactsProvider';
import useResponsive from 'hooks/responsive';

type Inputs = {
    applicationId: string;
    subject?: TaskSubject;
    contactId?: string;
    mediaType?: TaskMediaType;
    deadline: Date;
    priority: TaskPriority;
    comments?: string;
};

interface TaskFormProps {
    closeModal(): void;
    defaultValues?: Partial<Task>;
    disabledFields?: Array<DisabledField>;
    cancelButtonLabel?: string;
}

type CreateOrUpdateTaskResponse = {
    task: Task;
};

const createTaskRequest = async (inputs: Inputs): Promise<Task> => {
    const response = (await POST<CreateOrUpdateTaskResponse>(
        `${BACKEND_URL}/api/task`,
        inputs,
    )) as CreateOrUpdateTaskResponse;

    return response.task;
};

const updateTaskRequest = async (inputs: Inputs, id: string): Promise<Task> => {
    const response = (await PUT<CreateOrUpdateTaskResponse>(`${BACKEND_URL}/api/task/${id}`, {
        ...inputs,
    })) as CreateOrUpdateTaskResponse;

    return response.task;
};

const computeData = (task?: Partial<Task>): Partial<Inputs> | undefined => {
    if (!task) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { contact, id, deadline, application, ...data } = task;
    const computedData = {
        ...data,
        applicationId: application?.id,
        contactId: contact?.id,
        deadline: deadline ? new Date(deadline) : undefined,
    } as Partial<Inputs>;

    return computedData;
};

const TaskForm: FC<TaskFormProps> = ({ closeModal, defaultValues, disabledFields, cancelButtonLabel }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile } = useResponsive();
    const [contactOptions, setContactOptions] = useState<OptionsProps>([]);
    const [taskSubjectOptions, setTaskSubjectOptions] = useState<OptionsProps>([]);
    const [mediaTypeOptions, setMediaTypeOptions] = useState<OptionsProps>([]);
    const [taskPriorityOptions, setTaskPriorityOptions] = useState<OptionsProps>([]);
    const contacts = useSelector(contactsSelector);
    const applicationOptions = useSelector(applicationsSelector).map((application) => ({
        label: getApplicationLabel(application, t),
        value: application.id,
    }));

    const methods = useForm<Inputs>({
        defaultValues: computeData(defaultValues),
    });
    const { handleSubmit, control, watch, setValue } = methods;
    const selectedSubject = watch('subject');
    const selectedContactId = watch('contactId');
    const selectedApplicationId = watch('applicationId');
    const selectedContact = useSelector((state: RootState) => contactByIdSelector(state, selectedContactId));
    const selectedApplication = useSelector((state: RootState) =>
        applicationByIdSelector(state, selectedApplicationId),
    );

    const onSubmit = handleSubmit(async (values: Inputs) => {
        const computeValues = { ...values, deadline: computeDateWithoutTime(values.deadline) };
        try {
            if (defaultValues?.id) {
                // Update
                const updatedTask = await updateTaskRequest(computeValues, defaultValues.id);
                dispatch(UpdateTask(updatedTask));
                dispatch(CloseModal());
            } else {
                // Creation
                const newTask = await createTaskRequest(computeValues);
                dispatch(AddTask(newTask));
                logEvent({
                    name: TrackingEventName.TASK_CREATED,
                    params: { subject: newTask.subject, taskId: newTask.id },
                });
                dispatch(CloseModal());
            }

            // TODO: for contact deletion button recheck, fetch only updated data
            await contactsFetcher(dispatch, t);

            toast.success(t(`task-form.${defaultValues?.id ? 'update' : 'creation'}.success`));
        } catch (e) {
            toast.error(t(`task-form.${defaultValues?.id ? 'update' : 'creation'}.error`));
        }
    });

    useEffect(() => {
        const taskPriorityOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(TaskPriority)) {
            taskPriorityOptionsComputed.push({
                label: t(`common.task-priority.enum.${TaskPriority[value]}`),
                value: TaskPriority[value],
            });
        }

        setTaskPriorityOptions(taskPriorityOptionsComputed);
    }, [setTaskPriorityOptions]);

    useEffect(() => {
        // If subject selected is a task subject without contact, reset the contact
        if (selectedSubject && TaskSubjectWithoutContact.includes(selectedSubject)) {
            setValue('contactId', undefined);
        }
    }, [selectedSubject, setValue]);

    // Refresh subject options and contact options according to application selected or not
    useEffect(() => {
        const listOfSubject = selectedApplication ? TaskSubjectWithApplication : TaskSubjectWithoutApplication;

        if (defaultValues?.subject && !listOfSubject.includes(defaultValues.subject)) {
            listOfSubject.push(defaultValues.subject);
        }

        setTaskSubjectOptions(
            listOfSubject.map((subject) => ({
                label: t(`common.task-subject.enum.${subject}`),
                value: subject,
            })),
        );

        if (selectedSubject && !listOfSubject.includes(selectedSubject)) {
            setValue('subject', undefined);
        }

        const contactsComputed = contacts.filter(
            (contact) => !selectedApplication || selectedApplication.contacts.map((c) => c.id).includes(contact.id),
        );

        const contactIdsComputed = contactsComputed.map((contact) => contact.id);

        setContactOptions(
            contactsComputed.map((contact) => ({
                label: `${contact.firstName} ${contact.lastName}`,
                value: contact.id,
            })),
        );

        // If selected application have changed with a value, reset contact
        // because the contact should be in the list of application linked contact
        if (selectedApplication && selectedContactId && !contactIdsComputed.includes(selectedContactId)) {
            setValue('contactId', undefined);
        }
    }, [setTaskSubjectOptions, selectedApplication, setValue, selectedSubject, selectedContactId]);

    // Refresh of media type options according to selected contact
    useEffect(() => {
        if (selectedContact) {
            setMediaTypeOptions(getTaskMediaOptionsForContact(selectedContact, t));
        } else {
            // If unselect contact, reset mediatype selected and reset the list of choices
            setMediaTypeOptions([]);
            setValue('mediaType', undefined);
        }
    }, [setMediaTypeOptions, selectedContact, setValue]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SelectInput
                    control={control}
                    isDisabled={disabledFields && disabledFields.includes(DisabledField.ApplicationId)}
                    label={t('application.label')}
                    name="applicationId"
                    options={applicationOptions}
                    semi={!isMobile}
                />
                <SelectInput
                    control={control}
                    isDisabled={disabledFields && disabledFields.includes(DisabledField.Subject)}
                    label={t('common.subject.label')}
                    name="subject"
                    options={taskSubjectOptions}
                    semi={!isMobile}
                    required
                    
                />
                {(!selectedSubject || !TaskSubjectWithoutContact.includes(selectedSubject)) && (
                    <SSpacedColumn>
                        <SelectInput
                            control={control}
                            isDisabled={disabledFields && disabledFields.includes(DisabledField.ContactId)}
                            label={t('common.contact')}
                            name="contactId"
                            options={contactOptions}
                            placeholder={t('common.select-field-placeholder')}
                            // Field not required if task linked to an application
                            required={!selectedApplicationId}
                            semi={!isMobile}
                        />
                        {selectedContactId && (
                            <SelectInput
                                control={control}
                                label={t('common.media-type.label')}
                                name="mediaType"
                                options={mediaTypeOptions}
                                placeholder={t('common.select-field-placeholder')}
                                // Field not required if task linked to an application
                                semi={!isMobile}
                                required
                                
                            />
                        )}
                    </SSpacedColumn>
                )}
                <SSpacedColumn>
                    <DateOrTimeInput
                        control={control}
                        label={t('common.end-date')}
                        minDate={new Date()}
                        name="deadline"
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                    />
                    <SelectInput
                        control={control}
                        label={t('common.task-priority.short-label')}
                        name="priority"
                        options={taskPriorityOptions}
                        placeholder={t('common.select-field-placeholder')}
                        semi={!isMobile}
                        required
                    />
                </SSpacedColumn>
                <TextInput
                    label={t('common.comment')}
                    name="comments"
                    placeholder={t('common.write-a-comment')}
                    isTextArea
                />
                <ResourcesInput name="resources" />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {cancelButtonLabel || t('common.cancel')}
                    </Button>
                    <Button submit>{defaultValues?.id ? t('common.update') : t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default TaskForm;
