/* eslint-disable max-len */
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    SCard,
    SCardHeader,
    SCardHeaderLeft,
    SCardLine,
    SCardLineLeft,
    SCardLineRight,
    SSubject,
    STwoLines,
    SWrapper,
} from './styles';
import { SBoldPrimaryText, STable } from 'styles/components';
import Task from 'core/models/task';
import TaskPriorityIcon from 'components/Task/TaskPriorityIcon';
import { getContactLabel } from 'core/useCases/contacts/utils';
import { renderTaskMediaText } from 'core/useCases/tasks/utils';
import { TaskMediaType } from 'core/enums/taskMediaType';
import { ReactComponent as Checked } from 'images/icons/Checked.svg';
import TaskStatus from 'core/enums/taskStatus';
import { getCompleteDate, getNumericDate } from 'utils/dateTimeFormat';
import TaskActions from '../TaskActions';
import ActivityMediaType from 'components/Activity/ActivityMediaType';
import ApplicationSubjectItem from 'components/Application/ApplicationSubjectItem';
import MarkTaskAsCompleteButton from '../MarkTaskAsCompleteButton';
import useResponsive from 'hooks/responsive';

type TasksByDate = {
    tasks: Task[];
    date: Date;
};

type TaskListProps = {
    tasks: Task[];
};

const TaskList: FC<TaskListProps> = ({ tasks }) => {
    const { t } = useTranslation();
    const [sortDateDirection, setSortDateDirection] = useState<'asc' | 'desc'>('asc');
    const { isMobile } = useResponsive();

    const tasksByDateList = useMemo(
        () =>
            _(tasks)
                .groupBy('deadline')
                .map((items, date) => ({
                    date: new Date(date),
                    tasks: _.orderBy(items, ['initialDeadline'], [sortDateDirection]),
                }))
                .value(),
        [tasks, sortDateDirection],
    );

    const toggleSortDateDirection = () => {
        setSortDateDirection(sortDateDirection === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div>
            {tasksByDateList?.map((tasksByDate: TasksByDate) =>
                isMobile ? (
                    <SWrapper key={String(tasksByDate.date)}>
                        <h2>{getCompleteDate(tasksByDate.date, t)}</h2>
                        {tasksByDate.tasks.map((task: Task, index: number) => {
                            const { id, subject, mediaType, contact, initialDeadline, priority, status, application } =
                                task;

                            return (
                                <SCard key={id} isEven={index % 2 === 0}>
                                    <SCardHeader>
                                        <SCardHeaderLeft>
                                            {TaskStatus.NotStarted === status && (
                                                <MarkTaskAsCompleteButton taskId={id} />
                                            )}
                                            {TaskStatus.Completed === status && <Checked />}
                                            <Link title={t(`common.task-subject.enum.${subject}`)} to={`/task/${id}`}>
                                                <SSubject isCompleted={TaskStatus.Completed === status}>
                                                    {t(`common.task-subject.enum.${subject}`)}
                                                </SSubject>
                                            </Link>
                                        </SCardHeaderLeft>
                                        <TaskActions taskId={id} />
                                    </SCardHeader>
                                    <SCardLine>
                                        <SCardLineLeft>{t('application.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            <ApplicationSubjectItem application={application} />
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.media-type.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {contact && mediaType ? (
                                                <STwoLines>
                                                    <ActivityMediaType mediaType={mediaType} />
                                                    {renderTaskMediaText(mediaType, contact)}
                                                </STwoLines>
                                            ) : (
                                                <STwoLines>
                                                    <ActivityMediaType mediaType={TaskMediaType.Other} />
                                                    {application?.sourceDetails}
                                                </STwoLines>
                                            )}
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.contact')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {contact && (
                                                <>
                                                    <Link to={`/contact/${contact.id}`}>
                                                        <SBoldPrimaryText>
                                                            {getContactLabel(contact, t)}
                                                        </SBoldPrimaryText>
                                                    </Link>
                                                    <br />
                                                    <span>{contact.company}</span>
                                                </>
                                            )}
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.intermediary.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {contact && (
                                                <div>
                                                    {contact.parent ? (
                                                        <Link to={`/contact/${contact.parent.id}`}>
                                                            {getContactLabel(contact.parent, t)}
                                                        </Link>
                                                    ) : (
                                                        <span>{t('common.you')}</span>
                                                    )}
                                                </div>
                                            )}
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>
                                            <button onClick={toggleSortDateDirection} type="button">
                                                {t('common.end-date')}
                                                {sortDateDirection === 'asc' ? '▼' : '▲'}
                                            </button>
                                        </SCardLineLeft>
                                        <SCardLineRight>{getNumericDate(initialDeadline)}</SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.priority')}</SCardLineLeft>
                                        <SCardLineRight>
                                            <TaskPriorityIcon priority={priority} />
                                        </SCardLineRight>
                                    </SCardLine>
                                </SCard>
                            );
                        })}
                    </SWrapper>
                ) : (
                    <SWrapper key={String(tasksByDate.date)}>
                        <h2>{getCompleteDate(tasksByDate.date, t)}</h2>
                        <STable>
                            <thead>
                                <tr>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <th />
                                    <th>{t('common.subject.label')}</th>
                                    <th>{t('application.label')}</th>
                                    <th>{t('common.media-type.label')}</th>
                                    <th>{t('common.contact')}</th>
                                    <th>{t('common.intermediary.label')}</th>
                                    <th>
                                        <button onClick={toggleSortDateDirection} type="button">
                                            {t('common.end-date')}
                                            {sortDateDirection === 'asc' ? '▼' : '▲'}
                                        </button>
                                    </th>
                                    <th>{t('common.priority')}</th>
                                    <th aria-label={t('common.action')} />
                                </tr>
                            </thead>
                            <tbody className="table--task">
                                {tasksByDate.tasks.map((task: Task) => {
                                    const {
                                        id,
                                        subject,
                                        mediaType,
                                        contact,
                                        initialDeadline,
                                        priority,
                                        status,
                                        application,
                                    } = task;

                                    return (
                                        <tr key={id}>
                                            <td>
                                                {TaskStatus.NotStarted === status && (
                                                    <MarkTaskAsCompleteButton taskId={id} />
                                                )}
                                                {TaskStatus.Completed === status && <Checked />}
                                            </td>
                                            <td>
                                                <Link
                                                    title={t(`common.task-subject.enum.${subject}`)}
                                                    to={`/task/${id}`}
                                                >
                                                    <SSubject isCompleted={TaskStatus.Completed === status}>
                                                        {t(`common.task-subject.enum.${subject}`)}
                                                    </SSubject>
                                                </Link>
                                            </td>
                                            <td>
                                                <ApplicationSubjectItem application={application} />
                                            </td>
                                            <td>
                                                {contact && mediaType ? (
                                                    <>
                                                        <ActivityMediaType mediaType={mediaType} />
                                                        {renderTaskMediaText(mediaType, contact)}
                                                    </>
                                                ) : (
                                                    <>
                                                        <ActivityMediaType mediaType={TaskMediaType.Other} />
                                                        {application?.sourceDetails}
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {contact && (
                                                    <>
                                                        <Link to={`/contact/${contact.id}`}>
                                                            <SBoldPrimaryText>
                                                                {getContactLabel(contact, t)}
                                                            </SBoldPrimaryText>
                                                        </Link>
                                                        <br />
                                                        <span>{contact.company}</span>
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {contact && (
                                                    <div>
                                                        {contact.parent ? (
                                                            <Link to={`/contact/${contact.parent.id}`}>
                                                                {getContactLabel(contact.parent, t)}
                                                            </Link>
                                                        ) : (
                                                            <span>{t('common.you')}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            <td>{getNumericDate(initialDeadline)}</td>
                                            <td>
                                                <TaskPriorityIcon priority={priority} />
                                            </td>
                                            <td>
                                                <div className="showOnRowHover">
                                                    <TaskActions taskId={id} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </STable>
                    </SWrapper>
                ),
            )}
        </div>
    );
};

export default TaskList;
