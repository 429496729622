export enum TaskSubject {
    AskInformation = 'ask-information',
    FirstContact = 'first-contact',
    Reminder = 'reminder',
    ThanksAfterInterview = 'thanks-after-interview',
    ThanksForIntroduction = 'thanks-for-introduction',
    ThanksAfterRecruitmentFailed = 'thanks-after-recruitment-failed',
    RequestForInformation = 'request-for-information',
    SendInformation = 'send-information',
    SendOffer = 'send-offer',
    Feedback = 'feedback',
    WishCard = 'wish-card',
    UpdateJobboardsCvs = 'update-jobboards-cvs',
    SendApplication = 'send-application',
    ReminderApplication = 'reminder-application',
    InterviewPreparation = 'interview-preparation',
    InternetSearches = 'internet-searches',
}

export const TaskSubjectWithoutContact = [
    TaskSubject.UpdateJobboardsCvs,
    TaskSubject.InterviewPreparation,
    TaskSubject.InternetSearches,
];

export const TaskSubjectWithoutApplication = [
    TaskSubject.FirstContact,
    TaskSubject.Reminder,
    TaskSubject.RequestForInformation,
    TaskSubject.AskInformation,
    TaskSubject.SendInformation,
    TaskSubject.SendOffer,
    TaskSubject.Feedback,
    TaskSubject.WishCard,
    TaskSubject.UpdateJobboardsCvs,
    TaskSubject.InterviewPreparation,
    TaskSubject.InternetSearches,
];

export const TaskSubjectWithApplication = [
    TaskSubject.SendApplication,
    TaskSubject.ReminderApplication,
    TaskSubject.SendOffer,
];
