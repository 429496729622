import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CardWithTitle from 'components/CardWithTitle';
import NoMessage from 'components/NoMessage';
import { SButtonContainer, SWrapper, SButtonWrapper } from './styles';
import { SSubCard } from 'styles/components';
import { useDispatch } from 'react-redux';
import { ReactComponent as Hourglass } from 'images/Hourglass.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import { ReactComponent as CheckboxComplete } from 'images/CheckboxComplete.svg';
import ActivityType from 'core/enums/activityType';
import EventMood from 'core/enums/eventMood';
import ActivityReportVisualisation from '../ActivityReportVisualisation';
import MarkTaskAsCompleteButton from 'components/Task/MarkTaskAsCompleteButton';
import MarkEventAsCompleteButton from 'components/Event/MarkEventAsCompleteButton';

type ActivityCommentAndReportProps = {
    id: string;
    activityType: ActivityType;
    comments?: string;
    markAsCompleteComment?: string;
    isAlreadyCompleted: boolean;
    mood?: EventMood;
};

const ActivityCommentAndReport: FC<ActivityCommentAndReportProps> = ({
    id,
    activityType,
    comments,
    markAsCompleteComment,
    isAlreadyCompleted,
    mood,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openAdditionalInformationModal = () => {
        dispatch(
            OpenModal({
                defaultValues: { comments, id },
                title: t(`activity.additional-information.${comments ? 'update' : 'add'}.label`),
                type: ActivityType.Task === activityType ? ModalType.TaskAdditionalInfo : ModalType.EventAdditionalInfo,
            }),
        );
    };

    const openReportModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    activityType,
                    comment: markAsCompleteComment,
                    id,
                    isAlreadyCompleted,
                    mood,
                },
                title: t(`activity.report.${markAsCompleteComment ? 'update' : 'add'}.label`),
                type: ModalType.ActivityReport,
            }),
        );
    };

    return (
        <SWrapper>
            {comments ? (
                <CardWithTitle
                    actions={
                        <SButtonContainer>
                            <button
                                onClick={() => openAdditionalInformationModal()}
                                title={t('activity.additional-information.update.label')}
                                type="button"
                            >
                                <Edit />
                            </button>
                        </SButtonContainer>
                    }
                    label={t('common.additional-information')}
                >
                    <SSubCard>
                        <p>{comments}</p>
                    </SSubCard>
                </CardWithTitle>
            ) : (
                <NoMessage
                    icon={<Hourglass />}
                    label={t('common.add-information')}
                    onClick={openAdditionalInformationModal}
                    title={t('activity.additional-information.empty')}
                />
            )}
            {isAlreadyCompleted ? (
                <div>
                    {markAsCompleteComment || mood ? (
                        <CardWithTitle
                            actions={
                                <button
                                    onClick={() => openReportModal()}
                                    title={t('activity.report.update.label')}
                                    type="button"
                                >
                                    <Edit />
                                </button>
                            }
                            label={t('common.how-the-process-was')}
                        >
                            <ActivityReportVisualisation comment={markAsCompleteComment} mood={mood} />
                        </CardWithTitle>
                    ) : (
                        <NoMessage
                            icon={<CheckboxComplete />}
                            label={t('common.add-report')}
                            onClick={openReportModal}
                            title={t('activity.report.empty')}
                        />
                    )}
                </div>
            ) : (
                <SButtonWrapper>
                    {ActivityType.Task === activityType && <MarkTaskAsCompleteButton iconButton={false} taskId={id} />}
                    {ActivityType.Event === activityType && (
                        <MarkEventAsCompleteButton eventId={id} iconButton={false} />
                    )}
                </SButtonWrapper>
            )}
        </SWrapper>
    );
};

export default ActivityCommentAndReport;
