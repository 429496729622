import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SSubCard, SInfoLabel, SSubCards } from './styles';
import Task from 'core/models/task';
import { SRow } from 'styles/layout';
import CardWithTitle from 'components/CardWithTitle';
import { getNumericDate } from 'utils/dateTimeFormat';
import TaskStatus from 'core/enums/taskStatus';
import TaskActions from '../../TaskActions';
import ApplicationSubjectItem from 'components/Application/ApplicationSubjectItem';
import ResourceInformation from 'components/DetailsPage/ResourceInformation';

type InformationProps = {
    task: Task;
};

const Information: FC<InformationProps> = ({ task }) => {
    const { t } = useTranslation();

    const {
        id,
        subject,
        mediaType,
        priority,
        status,
        initialDeadline,
        application,
        resources: persistedResources,
    } = task;
    const resources = persistedResources ? JSON.parse(persistedResources) : [];

    return (
        <CardWithTitle
            actions={TaskStatus.Completed !== status && <TaskActions taskId={id} />}
            label={t(`common.task-subject.enum.${subject}`)}
        >
            <SSubCards>
                <SSubCard>
                    <SRow>
                        <SInfoLabel>{t('common.media-type.label')}</SInfoLabel>
                        {t(`common.media-type.enum.${mediaType}`)}
                    </SRow>
                    <SRow>
                        <SInfoLabel>{t('common.status')}</SInfoLabel>
                        {t(`common.task-status.enum.${status}`)}
                    </SRow>
                    <SRow>
                        <SInfoLabel>{t('common.task-priority.short-label')}</SInfoLabel>
                        {t(`common.task-priority.enum.${priority}`)}
                    </SRow>
                    <SRow>
                        <SInfoLabel>{t('common.end-date')}</SInfoLabel>
                        {getNumericDate(initialDeadline)}
                    </SRow>
                    {application && (
                        <SRow>
                            <SInfoLabel>{t('application.label')}</SInfoLabel>
                            <ApplicationSubjectItem application={application} isCompanyBold={false} />
                        </SRow>
                    )}
                </SSubCard>
                <ResourceInformation resources={resources} />
            </SSubCards>
        </CardWithTitle>
    );
};

export default Information;
