enum InterviewType {
    Presentation = 'presentation',
    JobInvestigation = 'job-investigation',
    MarketAppropriateness = 'market-appropriateness',
    Targeting = 'targeting',
    JobInterviewPreparation = 'job-interview-preparation',
    Other = 'other',
    PreSelection = 'pre-selection',
    Recruitment = 'recruitment',
    Meal = 'meal',
    Exhibition = 'exhibition',
    Webinar = 'webinar',
}

export default InterviewType;

export const InterviewTypeWithoutApplication = [
    InterviewType.Presentation,
    InterviewType.JobInvestigation,
    InterviewType.MarketAppropriateness,
    InterviewType.Targeting,
    InterviewType.JobInterviewPreparation,
    InterviewType.Meal,
    InterviewType.Exhibition,
    InterviewType.Webinar,
    InterviewType.Other,
];

export const InterviewTypeWithApplication = [InterviewType.PreSelection, InterviewType.Recruitment];
