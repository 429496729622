import React, { FC, useState } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import Application from 'core/models/application';
import MultiActionsButton from 'components/MultiActionsButton';
import Plus from 'images/icons/Plus.svg';
import Button from 'components/Button';
import { SWrapper, SButton, SSubCard } from './styles';
import EventType from 'core/enums/eventType';
import ApplicationStatus from 'core/enums/applicationStatus';
import CardWithTitle from 'components/CardWithTitle';
import TabButtonsGroup from 'components/TabButtonsGroup';
import NoMessage from 'components/NoMessage';
import TabButton from 'components/TabButton';
import { RootState } from 'core/store';
import { eventsByApplicationIdSortedByDateSelector } from 'core/useCases/events/selectors';
import { tasksByApplicationIdSortedByDeadlineSelector } from 'core/useCases/tasks/selectors';
import { computeActivitiesFromTasksAndEvents } from 'core/useCases/activities/utils';
import Event from 'core/models/event';
import Task from 'core/models/task';
import InProgressActivitiesSummary from 'components/Activity/InProgressActivitiesSummary';
import FinishedActivitiesSummary from 'components/Activity/FinishedActivitiesSummary';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import { ReactComponent as CheckboxComplete } from 'images/CheckboxComplete.svg';
import ApplicationActions from '../../ApplicationActions';

type ActivitiesProps = {
    application: Application;
};

const Activities: FC<ActivitiesProps> = ({ application }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [inProgressOptionSelected, setInProgressOptionSelected] = useState<boolean>(true);
    const { id: applicationId, createdDate, comment, status, description } = application;
    const creationDetails = {
        date: createdDate,
        label: t('application.created-at'),
    };

    const openTaskModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    application,
                },
                disabledFields: [DisabledField.ApplicationId],
                title: t('task-list.add-task'),
                type: ModalType.Task,
            }),
        );
    };

    const openInterviewModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    application,
                    type: EventType.Interview,
                },
                disabledFields: [DisabledField.ApplicationId],
                title: t('activities.add-interview'),
                type: ModalType.Event,
            }),
        );
    };

    const openReportModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    comment,
                    id: applicationId,
                    status,
                },
                title: t(`application.report.${comment ? 'update' : 'add'}.label`),
                type: ModalType.ApplicationReport,
            }),
        );
    };

    const events: Event[] = useSelector((state: RootState) =>
        eventsByApplicationIdSortedByDateSelector(state, applicationId),
    );

    const tasks: Task[] = useSelector((state: RootState) =>
        tasksByApplicationIdSortedByDeadlineSelector(state, applicationId),
    );

    const { finishedActivities, inProgressActivities } = computeActivitiesFromTasksAndEvents(tasks, events, t);

    finishedActivities.sort((a, b): number => {
        if (isBefore(new Date(a.date), new Date(b.date))) {
            return 1;
        }
        return -1;
    });

    inProgressActivities.sort((a, b): number => {
        if (isAfter(new Date(a.date), new Date(b.date))) {
            return 1;
        }
        return -1;
    });

    return (
        <SWrapper>
            <CardWithTitle label={t('common.activities-details')}>
                <SSubCard>
                    <TabButtonsGroup>
                        <TabButton
                            onClick={() => setInProgressOptionSelected(true)}
                            variant={inProgressOptionSelected ? 'plain' : 'text'}
                        >
                            {t('common.in-progress')}
                        </TabButton>
                        <TabButton
                            onClick={() => setInProgressOptionSelected(false)}
                            variant={inProgressOptionSelected ? 'text' : 'plain'}
                        >
                            {t('common.finished')}
                        </TabButton>
                    </TabButtonsGroup>
                    {inProgressOptionSelected ? (
                        <InProgressActivitiesSummary activities={inProgressActivities} />
                    ) : (
                        <FinishedActivitiesSummary activities={finishedActivities} creationDetails={creationDetails} />
                    )}
                </SSubCard>
                {/* TODO do we display buttons if application is finished ? */}
                <SButton>
                    <MultiActionsButton
                        parentButtonContent={
                            <>
                                <img alt="" src={Plus} width="20" />
                                {t('common.add')}
                            </>
                        }
                    >
                        <Button onClick={openTaskModal} variant="popinText">
                            {t('task.add')}
                        </Button>
                        <Button onClick={openInterviewModal} variant="popinText">
                            {t('interview.add')}
                        </Button>
                    </MultiActionsButton>
                </SButton>
            </CardWithTitle>
            {[ApplicationStatus.Win, ApplicationStatus.Lost].includes(status) && (
                <div>
                    {comment ? (
                        <CardWithTitle
                            actions={
                                <button
                                    onClick={() => openReportModal()}
                                    title={t('activity.report.update.label')}
                                    type="button"
                                >
                                    <Edit />
                                </button>
                            }
                            label={t('application.report.title')}
                        >
                            <SSubCard>
                                <p>{comment}</p>
                            </SSubCard>
                        </CardWithTitle>
                    ) : (
                        <NoMessage
                            icon={<CheckboxComplete />}
                            label={t('common.add-report')}
                            onClick={openReportModal}
                            title={t('application.report.empty')}
                        />
                    )}
                </div>
            )}
            {description && (
                <CardWithTitle
                    actions={<ApplicationActions applicationId={applicationId} />}
                    label={t('application.description.label')}
                >
                    <SSubCard>
                        <p>{description}</p>
                    </SSubCard>
                </CardWithTitle>
            )}
        </SWrapper>
    );
};

export default Activities;
